import React from "react";
import { container, content, smallText, dateAndAuthor } from "./index.module.less";
import Layout from "components/shared/Layout";

import { StaticImage } from "gatsby-plugin-image"
// export function Dino() {
//   return (
//     <StaticImage
//       src="../images/dino.png"
//       alt="A dinosaur"
//       placeholder="blurred"
//       layout="fixed"
//       width={200}
//       height={200}
//     />
//   )
// }

export default ({ children, title, extraInfo }) => (
  <header className={container}>
    {/* <StaticImage
        src="../../../images/photos/blog-header.jpg"
        alt="A dinosaur"
        placeholder="blurred"
        // layout="fixed"
        width={"100%"}
        height={"100vh"}
      /> */}
    
    <Layout>
      <div className={[content]}>
        {/* <h1>
          China 
            <span style={{color:"#fbd34c"}}>Healthy Ageing</span> Project
        </h1> */}
        {/* Title here */}
        
        <h1>
           {"中国健康老龄化项目 | 博客"}
        </h1>
        {/* Tiny Summary */}
        <p>了解更多关于我们项目的最新消息</p>
        {/* <p className={[smallText]}>
        这些活动是通过英国国家科研与创新署（UKRI），国际合作基金（FIC）提供资金支持，由英国创新署（UKRI的一部分）实施。
        </p> */}
      </div>
    </Layout>
  </header>
);
