import { container, grid, card, intro, readMoreLink, imgWrap, imgClass } from "./index.module.less";
import React from "react";
import blog1 from "images/photos/blog-1.jpg";
import blog2 from "images/photos/blog-2.jpg";
import blog3 from "images/photos/blog-3.jpg";

import { GatsbyImage, getImage } from "gatsby-plugin-image";


import { Link } from "gatsby";

let retrieveImage = (item) =>{
  if(item.featuredImage == null) return null
  // console.log("Retrieving Image")
  console.log(item.featuredImage?.node?.localFile?.publicURL);
  const image =  getImage(item.featuredImage?.node?.localFile);
  return image;
}

export default ({posts, maxCardsCount=-1}) => {
  console.log({maxCardsCount})
  let postsToDisplay = posts;
  if(maxCardsCount>=1 && posts.length > maxCardsCount){
    postsToDisplay = posts.slice(0,maxCardsCount);
  }

  return (
    <div className={container}>
      <div className={grid}>
        {postsToDisplay.map((it, idx) => (
          <div key={idx}>
            <Link className={card} to={"/" + it.id}>
              {it.featuredImage
                ? <GatsbyImage className={[imgClass]} image={retrieveImage(it)} alt={it.title} loading="eager" />
                : <img src={null} alt={it.title} />
              }
              <h3>{it.title}</h3>
              <span className={[readMoreLink]}>了解更多</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
};
