import React, {useEffect, useState} from "react";
import { container, imageContainer, textContainer, successClass, buttonClass } from "./index.module.less";

import Button from "components/shared/Button";
import Input from "../shared/Input";
import Layout from "components/shared/Layout";

export default () => {
    let [values, setValues] = useState({
        data_agreement: true,
    });
    let [loading, setLoading] = useState(false);
    let [sent, setSent] = useState(false);
    let [validateNow, setValidateNow] = useState(false);

    let handleChange = (value, name) => {
        setValidateNow(false);
        setValues({ ...values, [name]: value });
    };

    useEffect(() => {
        if (typeof window === "undefined") return;
        const urlObject = new URL(window.location.href);
        let email = urlObject.searchParams.get("email");
        if (!!email) {
        handleChange(decodeURIComponent(email), "email");
        }
    }, []);


    let terminate = () => {
        // console.log("finished");
        setSent(true)
    };

    let sendData = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setValidateNow(true);
        if (
            !values.name ||
            !values.email ||
            !values.message ||
            !values.data_agreement
        ) {
            // console.log("Missing Data...")
            return false;
        }

        if(!values.business){
            values.business = ""
        }

        if(!values.leadSource){
            values.leadSource = ""
        }

        setLoading(true);
        // console.log("Sending values. Updated", values)
        fetch(`https://o9cg02ppi6.execute-api.cn-north-1.amazonaws.com.cn/send-enquiry`, {
            method: "POST",
            body: JSON.stringify(values),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((data) => {
            // console.log("SUCCESS")
            if (data) {
                setLoading(false);
                terminate();
                console.log(data)
            }else{
                console.log("An error ocurred!!")
            }
        })
        .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
            alert(
                "An error happened, please try again later..."
            );
        });
    };

    return (
        <div className={[container]}>
            <Layout>
                <div style={{maxWidth:"850px", margin:"auto"}}>
                    <h1 style={{marginBottom:"8px"}}>联系我们</h1>
                    <p >请填写以下表格，致电18500122387，或发邮件咨询我们<a style={{color:"rgb(234 179 5)", fontSize:"1.2rem"}} href="mailto:someone@example.com">info.nica@appinchina.co</a></p>

                    <form style={{marginBottom:"24px"}} onSubmit={sendData}>
                        <Input
                            onChange={handleChange}
                            label="姓名 *"
                            value={values["name"]}
                            name="name"
                            disabled={loading || sent}
                            required={true}
                            errorMessage={"请输入您的姓名"}
                            validateNow={validateNow}
                        />
                        <Input
                            onChange={handleChange}
                            label="企业/组织"
                            value={values["business"]}
                            name="business"
                            disabled={loading || sent}
                            required={false}
                            errorMessage={""}
                            validateNow={validateNow}
                        />
                        <Input
                            onChange={handleChange}
                            label="联系方式 *"
                            value={values["email"]}
                            name="email"
                            type="email"
                            disabled={loading || sent}
                            required={true}
                            errorMessage={"请输入您的邮箱地址"}
                            validateNow={validateNow}
                        />
                        <Input
                            onChange={handleChange}
                            label="您是如何得知我们的？"
                            value={values["leadSource"]}
                            name="leadSource"
                            disabled={loading || sent}
                            required={false}
                            errorMessage={"Testing Error"}
                            validateNow={validateNow}
                        />
                        <Input
                            onChange={handleChange}
                            label="信息 *"
                            value={values["message"]}
                            name="message"
                            type="textarea"
                            required={true}
                            errorMessage={"请输入您的咨询内容"}
                            disabled={loading || sent}
                            validateNow={validateNow}
                        />
                        <Input
                            onChange={handleChange}
                            label="我同意按照隐私条款中所确定的有限的目的来收集和储存我提交的信息"
                            value={values["data_agreement"]}
                            name="data_agreement"
                            type="checkbox"
                            required={true}
                            errorMessage={"您需授权我们储存您的信息"}
                            disabled={loading || sent}
                            validateNow={validateNow}
                        />
                        <div className={buttonClass}>
                        {" "}
                        {sent ? (
                            <p className={successClass}>
                            {" "}
                            谢谢！ 我们团队的成员会尽快与您联系。
                            </p>
                        ) : (
                            <Button disabled={loading}>
                                {loading ? "加载..." : "提交咨询信息"}
                            </Button>
                        )}
                        </div>
                    </form>
                </div>
            </Layout>
        </div>
    );
};
  