import React from "react";
import { validateEmail } from "../../../utils/functions";
import { checkboxClass, container, error, noerror, checkContainer } from "./index.module.less";

let validateField = (type, value, validateNow, required, errorMessage) => {
  if (!validateNow) return null;
  if (type === "email") {
    if(required && !validateEmail(value)){
      return "请输入有效电子邮件。";
    }
  } else if (type === "checkbox") {
    if(required && (value == null || value == undefined || value == false)){
      return "您需授权我们储存您的信息";
    }
  } else {
    if(required && (value == null || value == undefined || value == false)){
      return errorMessage
    }
  }

  return null;
};

export default ({
  label,
  onChange,
  name,
  value,
  type,
  options,
  disabled,
  required,
  validateNow,
  errorMessage,
}) => {
  let errorFound = validateField(type, value, validateNow, required, errorMessage);
  return (
    <div
      className={[container, type === "checkbox" ? checkboxClass : ""].join(
        " "
      )}
    >
      {type !== "checkbox" 
        ? <label htmlFor={name}>{label}</label> 
        // ? null
        : null
      }
      {type === "textarea" ? (
        <textarea
          onChange={(e) => onChange(e.target.value, name)}
          name={name}
          value={value}
          disabled={disabled}
          // placeholder={label}
        ></textarea>
      ) : type === "select" ? (
        <select
          onChange={(e) => onChange(e.target.value, name)}
          disabled={disabled}
        >
          {options.map((it, key) => (
            <option key={key} value={it}>
              {it}
            </option>
          ))}
        </select>
      ) : type === "checkbox" ? (
        <div className={[checkContainer]}>
          <input
            type="checkbox"
            name={name}
            checked={value}
            onChange={(e) => onChange(e.target.checked, name)}
            disabled={disabled}
          />
          <p style={{marginLeft:"12px"}}>{label}</p>
        </div>
      ) : (
        <input
          onChange={(e) => onChange(e.target.value, name)}
          name={name}
          value={value}
          type={type === "number" ? "number" : "text"}
          disabled={disabled}
          // placeholder={label}
        />
      )}
      {error ? (
        <p className={error}>{errorFound}</p>
      ) : (
        <p className={noerror}>correct</p>
      )}
    </div>
  );
};
