import React from "react";
import baloons from "images/photos/chinese_ballons.jpg";
import { container, imageContainer, textContainer } from "./index.module.less";

import Layout from "components/shared/Layout";

export default ({ children }) => (
    <div className={[container]}>
      <div className={[imageContainer]}></div>

      <div className={[textContainer]}>
        <p>
          为了应对全球老龄化社会带来的复杂的社会、经济和医疗保健挑战与其所带来的机会，并利用全球智慧，英国国家科研与创新署（UKRI）正与中国国家自然科学基金委（NSFC）合作，开发英中健康老龄化创新流（HAIS）。作为其中的一部分，英国国家老龄化创新中心（NICA）正在开展一系列活动，推动创新，帮助英中两国的老年人更长久地过上快乐、健康和有意义的生活。
          <br></br>
          <br></br>
          这些活动是通过英国国家科研与创新署（UKRI），国际合作基金（FIC）提供资金支持，由英国创新署（UKRI的一部分）实施。
        </p>
      </div>
    </div>
);
