import React from "react";
import Cards from "components/StoriesGrid/Cards";
import Layout from "components/shared/Layout";
import { container, imageContainer, textContainer } from "./index.module.less";

export default ({blogPosts, maxCardsCount=-1}) => (
    <div className={[container]}>
        <Layout>
            <h1>最新消息</h1>
            <Cards posts={blogPosts} maxCardsCount={maxCardsCount}></Cards>
        </Layout>
    </div>
);